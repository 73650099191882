const msgs = {
    SOMETHING_WENT_WRONG: 'Something went wrong. Please, try again later.',
    FILL_REQUIRED_INFORMATION: 'Please fill in the required information.',
    CHECK_FILL_INFORMATION: "Something went wrong. Please check fill information valid or not and also try again later.",
    IMAGES_ARE_ALLOWED: 'Only images are allowed.',
    IMAGES_COUNT_ALLOWED: 'You can only send 5 images in one message',
    IMAGES_AND_VIDEOS_ARE_ALLOWED: 'Only images and video files are allowed.',
    IMAGE_WAS_CONVERTED_AUTOMATICALLY: 'Photo is more than 5Mb and was converted to relevant size',
    MAX_VIDEO_SIZE: 'Maximum video size - 50mb',
    MAX_IMG_SIZE: 'Maximum image size - 15mb',
    VALID_IMAGE_FORMATS: 'You can add image only with one of this formats: jpeg, jpg, png, gif',
    MAX_VIDEO_QUANTITY: 'Spark can contain only one video',
    NOT_ALLOWED_TO_VOTE: 'Very sorry, you are not allowed to vote.',
    GROUP_CREATE_SUCCESS: 'Group created successfully.',
    GROUP_FILL_REQUIRED: 'Please fill in the group title and short info fields. Also, please select group members.',
    SPARK_IS_ALREADY_IN_INSTANT: 'This Spark is already in this Instant.',
    SPARK_IS_ALREADY_IN_MOMENT: 'This Spark is already in this Instant.',
    CHAT_DELETED_SUCCESSFULLY: 'Your chat has been deleted successfully.',
    CHAT_DELETION_FAILURE: 'Your chat has been not been deleted. Error.',
    LEAVE_GROUP_SUCCESSFULLY: 'You have left the group successfully.',
    LEAVE_GROUP_FAILURE: 'You have not left the group. Error.',
    GROUP_UPDATED_SUCCESSFULLY: 'Group updated successfully.',
    ENTER_VALID_INPUT: 'Please enter valid input.',
    LINK_COPIED: 'Link copied successfully.',
    ENTER_A_COMMENT_TO_REPLY: 'Please enter a comment to reply on this post.',
    PASSWORD_RESET_SUCCESSFULLY: 'Password reset successfully.',
    PASSWORD_NOT_VALID: 'New password is not valid',
    PASSWORD_IS_REQUIRED: 'New password is required',
    CONFIRM_PASSWORD_IS_REQUIRED: 'Verify password is required',
    CURRENT_PASSWORD_IS_REQUIRED: 'Current password is required',
    INVALID_CREDIT_CARD_NUMBER: 'Credit card number is not valid.',
    MOBILE_HAS_BEEN_VERIFIED: 'Your mobile number has been successfully verified!',
    EMAIL_HAS_BEEN_VERIFIED: 'Your email address has been successfully verified!',
    PHONE_NUMBER_HAS_BEEN_CHANGED: 'Your phone number has been changed.',
    EMAIL_HAS_BEEN_CHANGED: 'Your email has been changed.',
    PLAN_IS_EXPIRED: 'Your plan is expired. Please subscribe plan to continue use this account.',
    PLAN_IS_RENEWED: 'Your plan renewed succesfully.',
    EMAIL_IS_NOT_VALID: 'Please enter valid email.',
    ACCEPT_TERMS: 'Please accept the Terms & Conditions and Privacy Policy.',
    PASSWORDS_DONT_MATCH: 'Passwords didn\'t match. Try again.',
    CURRENT_PASSWORD_NOT_CORRECT: 'Current password is not correct.',
    EMAIL_NOT_REGISTERED: 'Email not registered',
    CHAT_NAME_TOO_SHOT: 'Group name is empty',
    MESSAGE_LENGTH_LIMIT: 'Message length limit is 500 characters',
    TRIAL_SUBSCRIPTION_TOOLTIP: 'You cannot cancel a trial subscription',
    FREE_SUBSCRIPTION_TOOLTIP: 'You have a free lifetime account',
    FREE_SUBSCRIPTION_EXPIRATION: 'lifetime',
    ITUNES_SUBSCRIPTION_TOOLTIP: 'Your subscription was made on an Apple device through Apple Purchase, you can unsubscribe on Apple device with your ID only',
    SUBSCRIPTION_PLAN_CANCELED: 'Your subscription plan has been canceled',
    NO_INTERNET_CONNECTION: 'No Internet Connection. Check your network and try again.',

    //INSTANT CREATION
    INSTANT_CATEGORY_REQUIRED: 'Please choose a category.',
    INSTANT_TITLE_REQUIRED: 'Please enter a title',
    INSTANT_DESCRIPTION_REQUIRED: 'Please enter a description',
    INSTANT_IMAGE_REQUIRED: 'Please upload an image',
    INSTANT_SPARK_REQUIRED: 'Please add a spark',

    //EDIT PROFILE
    FIRST_NAME_REQUIRED: 'First Name is required.',
    LAST_NAME_REQUIRED: 'Last Name is required',

    //POLL 
    POLL_DATA_WILL_BE_DELETED: 'Are you sure? All poll data will be deleted.',
    ONLY_ONE_TYPE_OF_ATTACHMENT: 'You can add only one type of attachment. Current attachment will be deleted.',
    
    //SPARK CREATION
    SPARK_IMAGES_COUNT_ALLOWED: 'You can attach only 4 images in spark',
    ONLY_IMAGE_OR_VIDEO: 'You can add only 1 video or 4 images.',
};

export default msgs;
